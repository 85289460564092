import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import * as Helpers from "./common/Helpers";
import { placemnet } from "./common/Theme";
// create default theme to get breakpoints
const theme = createTheme();
const variables = {
    common: {
        borderRadius: 0,
    },
    colors: {
        primary: {
            main: "#ED1C24",
            dark: "#D02D25",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#FFFFFF",
            dark: "#000000",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#FF0012",
        },
        text: {
            primary: "#222",
            secondary: "#333",
            hint: "#666666",
        },
        background: "#FFFFFF",
        elementBackground: "#EEE",
        footerBackground: "#666",
        cardBackground: "#666",
        border: "#CCC",
        hover: "#EEE",
        hover2: "#AAAAAA",
        placeholder: "#888",
        transparent: "#00000000",
        divider: "#EEE",
        teaserBackground: "#CCC",
        entryBackground: "#333",
        background3: "#CCC",
        icons: {
            main: "#ED1C24",
        },
        gdprText: "#333",
        tooltipBackgroundColor: "#333",
        placeholderText: "#888",
    },
};
const defaultPathParams = {
    stroke: "currentColor",
    strokeWidth: "1.5",
    fill: "none",
};
function getFontFamily(mainFont) {
    return [
        mainFont ?? "",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
    ].join(",");
}
const props = Helpers.PrepareProperties(variables);
// More properties here: https://material-ui.com/customization/default-theme/
export const isuzumo = {
    themeName: "IsuzuMO",
    brand: BrandsEnum.IsuzuMO,
    compositionBoxWidth: 816,
    containerMaxWidth: 384,
    pageMaxWidth: 1336,
    centred: "0 auto",
    border: "1px solid ",
    colorCard: variables.colors,
    borderRadius: variables.common.borderRadius,
    shape: {
        borderRadius: variables.common.borderRadius,
    },
    header: {
        uspBannerBackground: variables.colors.text.primary,
        logoPlacement: placemnet.LEFT,
        dividerColor: variables.colors.elementBackground,
        dividerHeight: 1,
        navigationLinkColor: variables.colors.text.primary,
    },
    breadcrumbs: {
        activeColor: variables.colors.text.secondary,
        color: variables.colors.text.secondary,
        iconColor: variables.colors.text.primary,
        activeFontFamily: getFontFamily("Isuzu"),
    },
    contentBox: {
        backgroundColor: variables.colors.elementBackground,
        textColor: variables.colors.text.primary,
    },
    cardInformationBlock: {
        backgroundColor: variables.colors.cardBackground,
    },
    heroWithHeadline: {
        textColor: variables.colors.primary.contrastText,
        backgroundColor: variables.colors.text.secondary,
        body: {
            button: {
                primary: {
                    backgroundColor: variables.colors.primary.main,
                    color: variables.colors.primary.contrastText,
                    borderColor: variables.colors.primary.main,
                    "&:hover": {
                        backgroundColor: variables.colors.primary.dark,
                        border: variables.colors.primary.dark,
                        color: variables.colors.primary.contrastText,
                    },
                },
                secondary: {
                    color: variables.colors.primary.main,
                    backgroundColor: variables.colors.primary.contrastText,
                    borderColor: variables.colors.primary.main,
                    "&:hover": {
                        backgroundColor: variables.colors.primary.contrastText,
                        border: variables.colors.primary.dark,
                        color: variables.colors.primary.dark,
                    },
                },
            },
        },
    },
    webshopEntry: {
        backgroundColor: variables.colors.entryBackground,
        gdprTextColor: variables.colors.gdprText,
    },
    USPContainerBlock: {
        backgroundColor: variables.colors.elementBackground,
        textColor: variables.colors.text.secondary,
        headerColor: variables.colors.primary.main,
    },
    mobileAppBlock: {
        backgroundColor: variables.colors.elementBackground,
    },
    footer: {
        backgroundColor: variables.colors.footerBackground,
        border: true,
        borderColor: variables.colors.border,
        mobileFooterPlacement: placemnet.LEFT,
    },
    tooltip: {
        backgroundColor: variables.colors.tooltipBackgroundColor,
    },
    factAndIcon: {
        backgroundColor: variables.colors.elementBackground,
    },
    publishDate: {
        color: variables.colors.gdprText,
    },
    customStyling: {
        global: {
            textArea: {
                legalText: {
                    color: variables.colors.gdprText,
                },
            },
            primaryButton: {
                color: variables.colors.primary.contrastText,
                backgroundColor: variables.colors.primary.main,
                border: `1px solid ${variables.colors.primary.main}`,
                textDecoration: "none !important",
                "&:hover": {
                    color: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.dark,
                    border: `1px solid ${variables.colors.primary.dark}`,
                },
            },
            secondaryButton: {
                textDecoration: "none !important",
                color: variables.colors.primary.main,
                borderColor: variables.colors.primary.main,
                border: `2px solid ${variables.colors.primary.main}`,
                backgroundColor: variables.colors.primary.contrastText,
                "&:hover": {
                    color: variables.colors.primary.dark,
                    borderColor: variables.colors.primary.dark,
                    border: `2px solid ${variables.colors.primary.dark}`,
                    backgroundColor: variables.colors.primary.contrastText,
                },
            },
        },
        infoBody: {
            a: {
                color: variables.colors.text.primary,
            },
        },
        ContactUsAreaTabHead: {
            headerText: {
                fontFamily: getFontFamily("Isuzu-Roboto"),
            },
            tabsWrapper: {
                border: `1px solid ${variables.colors.hover2}`,
            },
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
        },
        ContactUsAreaTabBody: {
            textColor: {
                border: `1px solid ${variables.colors.hover2}`
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: "none",
                borderBottom: "none",
            }
        },
        Header: {
            menuButtonText: {
                color: variables.colors.text.secondary,
                fontWeight: 700,
            },
            loginText: {
                color: variables.colors.text.secondary,
                fontWeight: 700,
                "&:hover": {
                    textDecoration: "underline",
                },
            },
        },
        Navigation: {
            appmenu: {
                backgroundColor: variables.colors.background,
            },
        },
        NavigationItems: {
            text: {
                color: variables.colors.text.secondary,
                fontWeight: 700,
            },
            activeItem: {
                color: variables.colors.text.secondary,
                textDecoration: "underline",
            },
            itemWrapper: {
                borderBottom: `1px solid ${variables.colors.divider}`,
                "&:hover": {
                    backgroundColor: variables.colors.hover,
                    borderLeft: `2px solid ${variables.colors.primary.main}`,
                },
            },
            secondLevel: {
                fontFamily: getFontFamily("Isuzu-Evogria"),
            },
            thirdLevel: {
                marginTop: "24px",
            }
        },
        TextBlock: {
            textBlock: {
                color: variables.colors.text.secondary,
                "& a": {
                    color: variables.colors.text.secondary,
                },
                "& .text-area a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    "&:active": {
                        textDecoration: "underline",
                        color: variables.colors.primary.main,
                    },
                },
                "& .text-area p a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    "&:active": {
                        textDecoration: "underline",
                    },
                },
            },
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                "& a.button-secondary": {
                    color: variables.colors.primary.contrastText,
                    fontWeight: 700,
                    border: `2px solid ${variables.colors.primary.contrastText}`,
                    backgroundColor: "transparent",
                    "&:hover": {
                        color: variables.colors.hover2,
                        backgroundColor: "transparent",
                        border: `2px solid ${variables.colors.hover2}`,
                    },
                    "&:active": {
                        color: `${variables.colors.hover2} !important`,
                        backgroundColor: "transparent",
                        border: `2px solid ${variables.colors.hover2}`,
                    },
                },
            },
            heroWithHeadlineBody: {
                textArea: {
                    primaryButton: {
                        textDecoration: "none !important",
                        color: variables.colors.primary.contrastText,
                        fontFamily: getFontFamily("Isuzu-Roboto"),
                        backgroundColor: variables.colors.primary.main,
                        "&:hover": {
                            backgroundColor: variables.colors.primary.dark,
                            color: variables.colors.primary.contrastText,
                        },
                    },
                    containedPrimaryButton: {
                        textDecoration: "none !important",
                        fontFamily: getFontFamily("Isuzu-Roboto"),
                        backgroundColor: variables.colors.primary.main,
                        "&:hover": {
                            backgroundColor: variables.colors.primary.dark,
                            color: variables.colors.primary.contrastText,
                        },
                    },
                    secondaryButton: {
                        textDecoration: "none !important",
                        color: variables.colors.secondary.contrastText,
                        borderColor: variables.colors.secondary.contrastText,
                        border: `2px solid ${variables.colors.secondary.contrastText}`,
                        backgroundColor: variables.colors.transparent,
                        fontWeight: 700,
                        fontFamily: getFontFamily("Isuzu-Roboto"),
                        "&:hover": {
                            color: variables.colors.hover2,
                            borderColor: variables.colors.hover2,
                            border: `2px solid ${variables.colors.hover2}`,
                            backgroundColor: variables.colors.transparent,
                            fontWeight: 700,
                        },
                    },
                },
                outlinedPrimary: {
                    textDecoration: "none !important",
                    color: variables.colors.secondary.contrastText,
                    borderColor: variables.colors.secondary.contrastText,
                    border: `2px solid ${variables.colors.secondary.contrastText}`,
                    backgroundColor: variables.colors.transparent,
                    fontWeight: 700,
                    fontFamily: getFontFamily("Isuzu-Roboto"),
                    "&:hover": {
                        color: variables.colors.hover2,
                        borderColor: variables.colors.hover2,
                        border: `2px solid ${variables.colors.hover2}`,
                        backgroundColor: variables.colors.transparent,
                        fontWeight: 700,
                    },
                },
            },
        },
        CardInformationBlock: {
            root: {
                backgroundColor: variables.colors.cardBackground,
            },
            cardLink: {
                linkWithIcon: {
                    color: variables.colors.primary.contrastText,
                    "&:hover": {
                        color: variables.colors.hover2,
                    },
                    "&:active": {
                        color: variables.colors.hover2,
                    },
                },
            },
        },
        CallbackBlock: {
            gdprArea: {
                "& a": {
                    color: variables.colors.gdprText,
                    "&:hover": {
                        color: variables.colors.gdprText,
                    },
                },
            },
        },
        IMFGreenCardBlock: {
            gdprWrapper: {
                "& a": {
                    color: variables.colors.gdprText,
                    "&:hover": {
                        color: variables.colors.gdprText,
                    },
                },
            },
        },
        BreadCrumbs: {
            prevLink: {
                fontWeight: "400 !important",
            },
            currentLink: {
                fontWeight: 700
            },
            wrapper: {
                backgroundColor: variables.colors.elementBackground,
            },
        },
        CustomizedTextBox: {
            textBoxWrapper: {
                border: `1px solid ${"#666"}`,
            },
            textBoxInput: {
                backgroundColor: variables.colors.elementBackground,
                "&::placeholder": {
                    color: "#888888",
                    opacity: 1,
                },
            },
        },
        WebshopEntryBlock: {
            fullBackgroundWrapper: {
                background: variables.colors.entryBackground,
                "& input[type=text]": {
                    backgroundColor: variables.colors.background,
                },
                "& input[type=tel]": {
                    backgroundColor: variables.colors.background,
                },
                "& h4": {
                    color: variables.colors.primary.contrastText,
                },
                "& h2": {
                    color: variables.colors.primary.contrastText,
                },
                "& h3": {
                    color: variables.colors.primary.contrastText,
                },
                "& label": {
                    color: variables.colors.primary.contrastText,
                },
                "& .text-area p": {
                    color: variables.colors.primary.contrastText,
                },
                "& .text-area p a": {
                    color: variables.colors.primary.contrastText,
                },
                "& .MuiFormLabel-root": {
                    "&.Mui-focused": {
                        color: variables.colors.primary.contrastText,
                    },
                },
            },
        },
        TeaserFullPage: {
            teaserActions: {
                link: {
                    color: variables.colors.primary.main,
                    "&:hover": {
                        color: variables.colors.primary.dark,
                    },
                },
            },
        },
        Footer: {
            footerNoOverflow: {
                backgroundColor: variables.colors.footerBackground,
            },
            wrapper: {
                marginTop: 0,
                paddingTop: 40,
                "& .MuiLink-root": {
                    color: variables.colors.primary.contrastText,
                    "&:hover": {
                        color: variables.colors.hover2,
                    },
                    "&:active": {
                        color: variables.colors.hover2,
                    },
                },
                "& .MuiTypography-root": {
                    color: variables.colors.primary.contrastText,
                }
            },
            footerBottomWrapper: {
                backgroundColor: variables.colors.background,
                "& .MuiLink-root": {
                    color: variables.colors.text.primary,
                    "&:hover": {
                        color: variables.colors.text.primary,
                        textDecoration: "underline",
                    },
                    "&:active": {
                        color: variables.colors.primary.main,
                    },
                },
            },
        },
        FooterLinkBlocks: {
            divider: {
                backgroundColor: variables.colors.divider,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${variables.colors.border}`,
            },
        },
        MegaMenu: {
            rootLink: {
                fontFamily: getFontFamily("Isuzu-Evogria"),
                "& a": {
                    "& .MuiLink-underlineHover": {
                        color: variables.colors.text.primary,
                    },
                }
            }
        }
    },
    icons: {
        tooltipCloseIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none" },
            React.createElement("path", { d: "M8 8.66946L0.83682 15.8326C0.747559 15.9219 0.641562 15.9721 0.518828 15.9833C0.396095 15.9944 0.27894 15.9442 0.167364 15.8326C0.055788 15.7211 0 15.6095 0 15.4979C0 15.3863 0.055788 15.2748 0.167364 15.1632L7.33054 8L0.167364 0.83682C0.0781032 0.747559 0.027894 0.641562 0.0167364 0.518828C0.0055788 0.396095 0.055788 0.27894 0.167364 0.167364C0.27894 0.055788 0.390516 0 0.502092 0C0.613668 0 0.725244 0.055788 0.83682 0.167364L8 7.33054L15.1632 0.167364C15.2524 0.0781032 15.3584 0.027894 15.4812 0.0167364C15.6039 0.0055788 15.7211 0.055788 15.8326 0.167364C15.9442 0.27894 16 0.390516 16 0.502092C16 0.613668 15.9442 0.725244 15.8326 0.83682L8.66946 8L15.8326 15.1632C15.9219 15.2524 15.9721 15.3584 15.9833 15.4812C15.9944 15.6039 15.9442 15.7211 15.8326 15.8326C15.7211 15.9442 15.6095 16 15.4979 16C15.3863 16 15.2748 15.9442 15.1632 15.8326L8 8.66946Z", fill: "white" }))),
        secondaryHelpIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12.001 17C12.1153 17 12.2107 16.9614 12.2874 16.8843C12.364 16.8072 12.4023 16.7117 12.4023 16.5977V11.2529C12.4023 11.1389 12.3636 11.0433 12.2864 10.9662C12.2091 10.8891 12.1133 10.8506 11.999 10.8506C11.8847 10.8506 11.7893 10.8891 11.7126 10.9662C11.636 11.0433 11.5977 11.1389 11.5977 11.2529V16.5977C11.5977 16.7117 11.6364 16.8072 11.7136 16.8843C11.7909 16.9614 11.8867 17 12.001 17ZM12 9.41379C12.1628 9.41379 12.2993 9.35872 12.4095 9.24856C12.5196 9.13841 12.5747 9.00192 12.5747 8.83908C12.5747 8.67625 12.5196 8.53975 12.4095 8.4296C12.2993 8.31944 12.1628 8.26437 12 8.26437C11.8372 8.26437 11.7007 8.31944 11.5905 8.4296C11.4804 8.53975 11.4253 8.67625 11.4253 8.83908C11.4253 9.00192 11.4804 9.13841 11.5905 9.24856C11.7007 9.35872 11.8372 9.41379 12 9.41379ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "white" }))),
        comparisonTableExpand: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12 15.0282L19.0734 7.9548C19.1789 7.84934 19.307 7.79661 19.4576 7.79661C19.6083 7.79661 19.7363 7.84934 19.8418 7.9548C19.9473 8.06026 20 8.18832 20 8.33898C20 8.48964 19.9473 8.6177 19.8418 8.72316L12.9492 15.6158C12.8136 15.7514 12.6629 15.8493 12.4972 15.9096C12.3315 15.9699 12.1657 16 12 16C11.8343 16 11.6686 15.9699 11.5028 15.9096C11.3371 15.8493 11.1864 15.7514 11.0508 15.6158L4.15819 8.72316C4.05273 8.6177 4 8.48964 4 8.33898C4 8.18832 4.05273 8.06026 4.15819 7.9548C4.26365 7.84934 4.39171 7.79661 4.54237 7.79661C4.69303 7.79661 4.82109 7.84934 4.92655 7.9548L12 15.0282Z", fill: "#ED1C24" }))),
        comparisonTableExpandRotation: "180",
        expandIconNavigation: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#222222" }))),
        expandIconRotationNavigation: "45",
        expandIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#ED1C24" }))),
        expandIconRotation: "45",
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Tooltip" },
                React.createElement("path", { id: "Vector", d: "M12.001 17C12.1153 17 12.2107 16.9614 12.2874 16.8843C12.364 16.8072 12.4023 16.7117 12.4023 16.5977V11.2529C12.4023 11.1389 12.3636 11.0433 12.2864 10.9662C12.2091 10.8891 12.1133 10.8506 11.999 10.8506C11.8847 10.8506 11.7893 10.8891 11.7126 10.9662C11.636 11.0433 11.5977 11.1389 11.5977 11.2529V16.5977C11.5977 16.7117 11.6364 16.8072 11.7136 16.8843C11.7909 16.9614 11.8867 17 12.001 17ZM12 9.41379C12.1628 9.41379 12.2993 9.35872 12.4095 9.24856C12.5196 9.13841 12.5747 9.00192 12.5747 8.83908C12.5747 8.67625 12.5196 8.53975 12.4095 8.4296C12.2993 8.31944 12.1628 8.26437 12 8.26437C11.8372 8.26437 11.7007 8.31944 11.5905 8.4296C11.4804 8.53975 11.4253 8.67625 11.4253 8.83908C11.4253 9.00192 11.4804 9.13841 11.5905 9.24856C11.7007 9.35872 11.8372 9.41379 12 9.41379ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#ED1C24" })))),
        contactUsExpandIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#ED1C24" }))),
        checkmarkIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#ED1C24" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#ED1C24"/>
</svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M17.2134 25.1813L17.2222 25.1901L17.2311 25.1813L27.6717 14.7407C27.83 14.5824 27.9866 14.5049 28.1418 14.5049C28.2969 14.5049 28.4536 14.5824 28.6119 14.7407C28.7697 14.8985 28.8393 15.0627 28.8238 15.2336C28.8081 15.4062 28.7376 15.5552 28.6119 15.6809L18.2191 26.0736C17.934 26.3587 17.6018 26.501 17.2222 26.501C16.8426 26.501 16.5105 26.3587 16.2253 26.0736L11.2924 21.1406C11.1341 20.9823 11.0566 20.8257 11.0566 20.6705C11.0566 20.5154 11.1341 20.3587 11.2924 20.2005C11.4502 20.0426 11.6144 19.973 11.7853 19.9885C11.9579 20.0042 12.1069 20.0747 12.2326 20.2005L17.2134 25.1813ZM20.0083 36.6542C17.7056 36.6542 15.5404 36.2173 13.5127 35.3434C11.4848 34.4694 9.72085 33.2834 8.22087 31.7853C6.72091 30.2872 5.53339 28.5255 4.65832 26.5002C3.78336 24.475 3.34584 22.3112 3.34584 20.0084C3.34584 17.7056 3.7828 15.5404 4.65665 13.5127C5.53064 11.4848 6.71667 9.7209 8.21475 8.22094C9.71285 6.72096 11.4745 5.53343 13.4999 4.65836C15.525 3.78339 17.6889 3.34587 19.9917 3.34587C22.2945 3.34587 24.4596 3.78283 26.4873 4.65668C28.5153 5.53067 30.2792 6.71671 31.7791 8.21478C33.2791 9.71288 34.4666 11.4746 35.3417 13.4999C36.2167 15.525 36.6542 17.6889 36.6542 19.9917C36.6542 22.2945 36.2172 24.4597 35.3434 26.4873C34.4694 28.5153 33.2833 30.2792 31.7853 31.7792C30.2872 33.2791 28.5255 34.4667 26.5001 35.3417C24.475 36.2167 22.3111 36.6542 20.0083 36.6542ZM20 35.3382C24.2817 35.3382 27.9088 33.8523 30.8805 30.8805C33.8522 27.9088 35.3382 24.2817 35.3382 20C35.3382 15.7184 33.8522 12.0913 30.8805 9.11955C27.9088 6.14781 24.2817 4.66187 20 4.66187C15.7183 4.66187 12.0913 6.14781 9.11952 9.11955C6.14778 12.0913 4.66184 15.7184 4.66184 20C4.66184 24.2817 6.14778 27.9088 9.11952 30.8805C12.0913 33.8523 15.7183 35.3382 20 35.3382Z" fill="white" stroke="white" stroke-width="0.025" />
            </svg>
`),
        closeIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#ED1C24" }))),
        excludeIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#ED1C24" }))),
        bulletIconCSS: encodeURIComponent(`
                < svg
        xmlns = "http://www.w3.org/2000/svg"
        width = "14"
        height = "1.6"
        viewBox = "0 0 14 1.6"
                >
                <line
                    id="Line_861"
                    data-name="Line 861"
                    x2="14"
                    transform="translate(0 0.8)"
                    fill="none"
                    stroke="#001e50"
                    stroke-width="1.6"
                />
      </svg >
    `),
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Search" },
                React.createElement("path", { id: "Vector", d: "M8.97306 15.9461C7.02905 15.9461 5.38088 15.2699 4.02853 13.9176C2.67618 12.5652 2 10.9171 2 8.97306C2 7.02905 2.67618 5.38088 4.02853 4.02853C5.38088 2.67618 7.02905 2 8.97306 2C10.9171 2 12.5652 2.67618 13.9176 4.02853C15.2699 5.38088 15.9461 7.02905 15.9461 8.97306C15.9461 9.83941 15.7876 10.6846 15.4707 11.5087C15.1537 12.3328 14.7417 13.0301 14.2345 13.6006L21.8415 21.2076C21.926 21.2921 21.9736 21.3925 21.9842 21.5087C21.9947 21.6249 21.9472 21.7359 21.8415 21.8415C21.7359 21.9472 21.6302 22 21.5246 22C21.4189 22 21.3133 21.9472 21.2076 21.8415L13.6006 14.2345C12.9667 14.7839 12.2377 15.2065 11.4136 15.5024C10.5895 15.7982 9.77602 15.9461 8.97306 15.9461ZM8.97306 15.0586C10.6846 15.0586 12.1268 14.4723 13.2995 13.2995C14.4723 12.1268 15.0586 10.6846 15.0586 8.97306C15.0586 7.26149 14.4723 5.81933 13.2995 4.64659C12.1268 3.47385 10.6846 2.88748 8.97306 2.88748C7.26149 2.88748 5.81933 3.47385 4.64659 4.64659C3.47385 5.81933 2.88748 7.26149 2.88748 8.97306C2.88748 10.6846 3.47385 12.1268 4.64659 13.2995C5.81933 14.4723 7.26149 15.0586 8.97306 15.0586Z", fill: "#ED1C24" })))),
    },
    link: {
        showArrow: false,
        showUnderline: false,
    },
    accordionBlock: {
        expandedBackgroundColor: variables.colors.background,
        comparationTableBackground: variables.colors.background,
        backgroundColor: variables.colors.elementBackground,
        borderColor: variables.colors.border,
    },
    tetriaryLink: {
        underlined: false,
    },
    textBlock: {
        tableBackground: variables.colors.teaserBackground,
        tableBorderColor: variables.colors.border,
        linkHoverColor: variables.colors.text.primary,
    },
    contactUs: {
        backgroundColor: variables.colors.border,
        activeBackgroundColor: variables.colors.divider,
    },
    topCarUSP: {
        gradientColor: variables.colors.divider,
    },
    palette: {
        primary: variables.colors.primary,
        secondary: variables.colors.secondary,
        error: variables.colors.error,
        text: variables.colors.text,
        backgroundSecondary: {
            default: variables.colors.background,
            paper: variables.colors.divider,
        },
        background: {
            default: variables.colors.background,
            paper: variables.colors.divider,
        },
        divider: variables.colors.border,
        grey: {
            400: variables.colors.border,
        },
        icons: {
            main: variables.colors.icons.main,
        },
    },
    typography: {
        fontFamily: getFontFamily("Isuzu-Roboto"),
        color: variables.colors.text.primary,
        root: {
            fontFamily: getFontFamily("Isuzu-Roboto"),
        },
        h1: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h5: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            fontSize: "1.125rem",
            color: variables.colors.primary.main,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h6: {
            fontFamily: getFontFamily("Isuzu-Evogria"),
            color: variables.colors.primary.main,
            "word-break": "break-word",
            fontSize: "1rem",
            lineHeight: "1.75rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: getFontFamily("Isuzu-Roboto"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: getFontFamily("Isuzu-Roboto"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.primary,
            fontWeight: 400,
        },
        body1: {
            fontFamily: getFontFamily("Isuzu-Roboto"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.secondary,
        },
        body2: {
            fontFamily: getFontFamily("Isuzu-Roboto"),
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: variables.colors.primary.main,
        },
    },
    teaser: {
        root: {
            backgroundColor: variables.colors.background,
            border: false,
        },
        container: {
            backgroundColor: variables.colors.cardBackground,
        },
    },
    navigation: {
        backgroundColor: variables.colors.elementBackground,
        divider: variables.colors.divider,
        iconColor: variables.colors.text.secondary,
        megaBackgroundColor: variables.colors.elementBackground,
        mobileCollapseBackgroundColor: variables.colors.background,
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: getFontFamily("Isuzu-Roboto"),
            },
            colorTextSecondary: {
                color: variables.colors.text.secondary,
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: `none`,
            },
            input: {
                borderRadius: 0,
                border: `1px solid ${variables.colors.border} `,
                "&::placeholder": {
                    color: variables.colors.placeholderText,
                },
            },
            notchedOutline: {
                border: `none`,
            },
        },
        MuiListItem: {
            root: {
                width: "unset",
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: variables.colors.text.secondary,
                    fontWeight: 700,
                    textDecoration: "none",
                    "&:hover": {
                        color: variables.colors.text.secondary,
                        textDecoration: "underline",
                    },
                    "&:active": {
                        color: variables.colors.primary.main,
                        textDecoration: "none",
                    },
                },
            },
        },
        MuiMobileStepper: {
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiTooltip: {
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: variables.colors.transparent,
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiFormLabel: {
            root: {
                "&.Mui-focused": {
                    color: variables.colors.text.primary,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 3,
                "text-transform": "none",
            },
            outlined: {
                color: variables.colors.primary.main,
                borderColor: variables.colors.primary.main,
                border: `2px solid ${variables.colors.primary.main} `,
                backgroundColor: variables.colors.primary.contrastText,
                fontWeight: 700,
                "&:hover": {
                    color: variables.colors.primary.dark,
                    borderColor: variables.colors.primary.dark,
                    border: `2px solid ${variables.colors.primary.dark} `,
                    backgroundColor: variables.colors.primary.contrastText,
                },
            },
            contained: {
                boxShadow: props.buttons.boxShadow,
                "&:hover": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&:active": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&$focusVisible": {
                    boxShadow: props.buttons.boxShadow,
                },
                "a&": {
                    color: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.main,
                    textDecoration: "none",
                    fontWeight: 700,
                    "&:hover": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                    "&:active": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                },
            },
            outlinedPrimary: {
                color: variables.colors.primary.main,
                borderColor: variables.colors.primary.main,
                border: `2px solid ${variables.colors.primary.main} `,
                backgroundColor: variables.colors.primary.contrastText,
                fontWeight: 700,
                "&:hover": {
                    color: variables.colors.primary.dark,
                    borderColor: variables.colors.primary.dark,
                    border: `2px solid ${variables.colors.primary.dark} `,
                    backgroundColor: variables.colors.primary.contrastText,
                },
            },
        },
        MuiInputLabel: {
            formControl: {
                transform: "none",
                color: variables.colors.text.primary,
                lineHeight: "24px",
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
    },
};
export default createTheme(isuzumo);
