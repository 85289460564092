import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#05141F",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F7F7F8",
    accordionClosed: "#F7F7F8",
    tableOutline: "#CDD0D2",
    dividerLine: "#CDD0D2",
    secondaryHover: "#CDD0D2",
    background3: "#9BA1A5",
    wcagOutline: "#7F7F7F",
    inputOutline: "#7F7F7F",
    placeholderText: "#7F7F7F",
    wcag: "#697278",
    gdprText: "#37434B",
    helperText: "#37434B",
    primaryHover: "#697278",
    tetriaryHover: "#697278",
    subhead: "#37434B",
    bodyText: "#37434B",
    headlines: "#05141F",
    label: "#05141F",
    inputText: "#05141F",
    success: "#5D7D2B",
    successBackground: "#E5EADD",
    info: "#00AAE6",
    infoBackground: "#D6F1FB",
    warning: "#F3C300",
    warningBackground: "#FDF5D6",
    error: "#EA0029",
    errorBackground: "#FCD6DC",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const kia = {
    themeName: "kia",
    colorCard: colorCard,
    brand: BrandsEnum.Kia,
    shape: {
        borderRadius: 0,
    },
    customStyling: {
        global: {
            textArea: {
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            tetriaryButton: {
                textDecoration: "underline",
            },
            tetriaryLink: {
                textDecoration: "underline",
            },
            secondaryButton: {
                "&:hover": {
                    backgroundColor: "rgba(5, 20, 31, 0.04)",
                },
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.accordionClosed}`,
                borderBottom: `1px solid ${colorCard.accordionClosed}`,
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.bodyText,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.bodyText,
            },
        },
        CustomizedTextBox: {
            textBoxInput: {
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.background,
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.tetriaryHover}`,
                borderBottom: `1px solid ${colorCard?.tetriaryHover}`,
                backgroundColor: colorCard.primary,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    background: colorCard.tetriaryHover,
                },
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            footerBottomWrapper: {
                background: colorCard.primary,
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
        },
        USPContainerBlock: {
            wrapper: {
                "& .MuiTypography-h3": {
                    color: colorCard.background,
                },
                "& .MuiTypography-body1": {
                    color: colorCard.background2,
                },
            },
        },
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background,
        divider: colorCard.dividerLine,
    },
    contactUs: {
        backgroundColor: colorCard.dividerLine,
    },
    searchBox: {
        open: {
            border: "1px solid #7F7F7F",
            borderRadius: "0px",
        },
        inputColor: "#323537",
        placeholderColor: "#323537",
        backgroundColor: "#FFFFFF",
    },
    searchResultsPage: {
        headerBackgroundColor: "#CDD0D2",
        filterSectionTextColor: "#4D4D4D",
        activeFilterTextColor: "#333",
        filterSectionBackgroundColor: "#F2F4F6",
        backgroundColor: "#FFFFFF",
        subheadTextColor: "#05141F",
        resultTitleColor: "#05141F",
        resultDecsriptionColor: "#37434B",
        resultLinkColor: "#05141F",
        resultLinkHoverColor: "#05141F",
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "KIA_Icon_Search" },
                React.createElement("path", { id: "Vector", d: "M20.71 19.16L14.55 13C15.24 11.99 15.65 10.76 15.65 9.45C15.65 5.96 12.81 3.13 9.33 3.13C5.85 3.13 3 5.97 3 9.45C3 12.93 5.84 15.77 9.32 15.77C10.77 15.77 12.1 15.27 13.17 14.45L19.29 20.57L20.7 19.16H20.71ZM9.33 13.77C6.95 13.77 5.01 11.83 5.01 9.45C5.01 7.07 6.95 5.13 9.33 5.13C11.71 5.13 13.65 7.07 13.65 9.45C13.65 11.83 11.71 13.77 9.33 13.77Z", fill: "#05141F" })))),
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M22 7L11.9999 17.5722L2 7L6.02863 7L11.9999 13.3409L17.9714 7L22 7Z", fill: "#05141F" }))),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotationNavigation: "180",
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 6.99994L13 8.99994L11 8.99994L11 6.99994L13 6.99994ZM13 9.99994L13 16.9999L11 16.9999L11 9.99994L13 9.99994ZM12 19.9999C13.5823 19.9999 15.129 19.5308 16.4445 18.6518C17.7601 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9912 19.8463 10.4394C19.5376 8.88754 18.7757 7.46202 17.6569 6.3432C16.538 5.22438 15.1126 4.46243 13.5607 4.15375C12.0089 3.84507 10.4003 4.00333 8.93854 4.60883C7.47673 5.21433 6.22729 6.24001 5.34823 7.5556C4.46918 8.8712 4 10.4177 4 11.9999C4.00528 14.12 4.84982 16.1517 6.34897 17.6508C7.84812 19.15 9.87989 19.9947 12 19.9999Z", fill: "#05141F" }))),
        contactUsExpandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M22 7.34858L10.2386 19.1287L2 10.89L4.34858 8.54152L10.2386 14.4129L19.6514 5L22 7.34858Z", fill: "#05141F" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22 7.34858L10.2386 19.1287L2 10.89L4.34858 8.54152L10.2386 14.4129L19.6514 5L22 7.34858Z" fill="#05141F" />
  </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 7.34858L10.2386 19.1287L2 10.89L4.34858 8.54152L10.2386 14.4129L19.6514 5L22 7.34858Z" fill="#F7F7F8"/>
      </svg>`),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "#05141F" }))),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "currentColor" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Kia Signature"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Kia Signature"),
        },
        h1: {
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Kia Signature"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.primary,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.secondaryHover,
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                border: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(kia);
