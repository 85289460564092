import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#C3002F",
    secondary: "#4E5844",
    tertiary: "#CCCCCC",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#FFFFFF",
    accordionClosed: "#F5F4F3",
    tableOutline: "#E5E6E6",
    dividerLine: "#E5E6E6",
    background3: "#E5E6E6",
    wcagOutline: "#8A8A8A",
    inputOutline: "#8A8A8A",
    placeholderText: "#8A8A8A",
    wcag: "#5C5C5C",
    gdprText: "#5C5C5C",
    helperText: "#5C5C5C",
    primaryHover: "#5C5C5C",
    secondaryHover: "#FFFFFF",
    tetriaryHover: "#FFFFFF",
    subhead: "#333333",
    bodyText: "#333333",
    headlines: "#020A0D",
    label: "#020A0D",
    inputText: "#1D252C",
    success: "#508F40",
    successBackground: "#E3EDE0",
    info: "#1E60BA",
    infoBackground: "#DBE5F4",
    warning: "#FFCE00",
    warningBackground: "#FFF7D6",
    error: "#EC3640",
    errorBackground: "#FCDFE0",
    footerBackground: "#333333"
};
export const nissanno = {
    themeName: "NissanNO",
    brand: BrandsEnum.NissanNO,
    colorCard: colorCard,
    shape: {
        borderRadius: 4,
    },
    customStyling: {
        global: {
            secondaryButton: {
                color: colorCard.headlines,
                backgroundColor: colorCard.background,
                fontFamily: GetFontFamily("Nissan"),
                fontWeight: 500,
                borderRadius: 25,
                border: `1px solid ${colorCard.headlines}`,
                "&:hover": {
                    color: colorCard.primaryHover,
                    border: `1px solid ${colorCard.primaryHover}`,
                    backgroundColor: colorCard.background,
                },
            },
            primaryButton: {
                color: colorCard.background,
                backgroundColor: colorCard.headlines,
                borderRadius: 25,
                border: `1px solid ${colorCard.headlines}`,
                fontWeight: 500,
                fontFamily: GetFontFamily("Nissan"),
                "&:hover": {
                    color: colorCard.background,
                    backgroundColor: colorCard.primaryHover,
                    border: `1px solid ${colorCard.primaryHover}`,
                },
            },
            textArea: {
                a: {
                    color: colorCard.headlines,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
                legalText: {
                    color: colorCard.gdprText,
                },
                h1: {
                    fontWeight: 700
                },
                h2: {
                    fontWeight: 700
                },
                h3: {
                    fontWeight: 700
                },
            },
        },
        CallbackBlock: {
            gdprArea: {
                "& a": {
                    color: colorCard?.gdprText,
                    "&:hover": {
                        color: colorCard?.primary,
                    },
                },
            },
        },
        FactAndIcon: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
            header: {
                color: colorCard?.label,
            }
        },
        factAndIcon: {
            header: {
                color: colorCard?.label,
            }
        },
        MegaMenu: {
            rootLink: {
                color: colorCard.headlines
            },
            linkIcon: {
                fill: colorCard.primary,
            },
        },
        USPContainerBlock: {
            wrapper: {
                background: `linear-gradient(to bottom, ${colorCard.subhead} 0%, 
                ${colorCard.subhead} 100%) no-repeat`,
            }
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        Header: {
            menuButtonText: {
                color: colorCard.headlines,
            },
            loginText: {
                color: colorCard.headlines,
            },
            link: {
                hover: {
                    textDecoration: "underline",
                },
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                color: colorCard.headlines,
                fontWeight: 700,
                textDecoration: "underline",
                lineHeight: "1.5rem",
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "underline",
                },
            },
            icon: {
                fontSize: "1.5rem",
            }
        },
        LinkWithIcon: {
            link: {
                color: colorCard.headlines,
                textDecoration: "underline",
                fontWeight: 700,
                fontFamily: GetFontFamily("Nissan"),
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "underline",
                },
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            link: {
                linkWithIcon: {
                    textDecoration: "underline",
                    fontWeight: 700,
                    "&:hover": {
                        color: colorCard.primaryHover,
                        textDecoration: "underline",
                    },
                },
            },
        },
        USPBlock: {
            header: {
                color: "#FFFFFF"
            },
            text: {
                color: "#FFFFFF"
            }
        },
        TeaserFullPage: {
            teaserActions: {
                link: {
                    color: colorCard.headlines,
                    fontWeight: 700,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
        },
        ContactUsAreaTabHead: {
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
            activeHeaderText: {
                color: colorCard.headlines,
            },
        },
        Item: {
            link: {
                hover: {
                    textDecoration: "underline",
                },
            },
        },
        Navigation: {
            appmenu: {
                backgroundColor: colorCard.background,
            },
            menuButtonText: {
                color: colorCard.headlines,
            },
        },
        NavigationItems: {
            wrapper: {
                backgroundColor: colorCard.background,
            },
            item: {
                textTransform: "uppercase",
                fontWeight: 500,
                hover: {
                    textDecoration: "underline",
                    textUnderlineOffset: '8px'
                },
            },
            itemWrapper: {
                "&:hover": {
                    backgroundColor: colorCard.accordionClosed,
                    borderLeft: `4px solid ${colorCard.label}`,
                    textDecoration: "underline",
                },
                "& .active": {
                    backgroundColor: colorCard.accordionClosed,
                    borderLeft: `4px solid ${colorCard.label}`,
                    textDecoration: "underline",
                },
            },
            activeItem: {
                color: colorCard.headlines,
                fontWeight: 700,
            },
        },
        IMFGreenCardBlock: {
            gdprWrapper: {
                "& a": {
                    color: colorCard.gdprText,
                    "&:hover": {
                        color: colorCard.primary,
                    },
                },
            },
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area p a": {
                    color: colorCard.headlines,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
            fullBackgroundWrapper: {
                background: colorCard.tertiary,
            },
        },
        CardInformationBlock: {
            root: {
                background: colorCard.subhead,
            },
            cardLink: {
                linkWithIcon: {
                    color: colorCard.background,
                    textDecoration: "underline",
                    fontWeight: 700,
                    "&:hover": {
                        color: colorCard.tertiary,
                        textDecoration: "underline",
                    },
                },
            },
        },
        BraviContentPage: {
            heroWithHeadlineBody: {
                textArea: {
                    primaryButton: {
                        color: colorCard.subhead,
                        fontFamily: GetFontFamily("Nissan"),
                        fontWeight: 500,
                        borderRadius: 25,
                        "&:hover": {
                            color: colorCard.primaryHover,
                            backgroundColor: colorCard.dividerLine
                        },
                    },
                },
            },
            heroWithHeadlineWrapper: {
                backgroundColor: colorCard.subhead,
            },
        },
        TextBlock: {
            textBlock: {
                "& .text-area .button-secondary a": {
                    color: colorCard.label,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
                "& .text-area .button-secondary": {
                    "&:hover": {
                        borderColor: colorCard.primaryHover,
                    },
                },
            },
        },
        CustomizedTextBoxTooltip: {
            tooltipHeader: {
                color: colorCard.accordionClosed,
                fontWeight: 700,
            },
            tooltipBody: {
                color: colorCard.accordionClosed,
                fontWeight: 400,
            },
        },
        Footer: {
            wrapper: {
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    backgroundColor: colorCard.helperText,
                },
                "& .MuiLink-root": {
                    color: colorCard.tertiary,
                    textDecoration: "none",
                    "&:hover": {
                        color: colorCard.primaryHover,
                        textDecoration: "none",
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                }
            },
            footerBottomWrapper: {
                background: colorCard.footerBackground,
                color: colorCard.background3,
                "& span": {
                    color: colorCard.background3,
                },
                "& .MuiLink-root": {
                    color: colorCard.background3,
                    textDecoration: "none",
                    "&:hover": {
                        color: colorCard.primaryHover,
                        textDecoration: "none",
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                }
            },
        },
    },
    tooltip: {
        backgroundColor: colorCard.headlines,
    },
    contentBox: {
        backgroundColor: colorCard.background,
    },
    factAndIcon: {
        backgroundColor: colorCard.background,
    },
    accordionBlock: {
        backgroundColor: colorCard.accordionClosed,
        expandedBackgroundColor: colorCard.background,
        borderColor: colorCard.dividerLine,
    },
    navigation: {
        divider: colorCard.dividerLine,
    },
    footer: {
        backgroundColor: colorCard.footerBackground,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.bodyText
    },
    teaser: {
        root: {
            border: true,
            borderColor: colorCard.accordionClosed
        }
    },
    breadcrumbs: {
        activeColor: colorCard.headlines,
        color: colorCard.subhead,
        iconColor: colorCard.primary,
    },
    header: {
        uspBannerBackground: colorCard.bodyText,
        navigationLinkColor: colorCard.headlines,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
    },
    heroWithHeadline: {
        textColor: colorCard.background,
        backgroundColor: colorCard.subhead,
        body: {
            button: {
                primary: {
                    color: colorCard.bodyText,
                    backgroundColor: colorCard.background,
                    borderColor: colorCard.bodyText,
                },
                secondary: {
                    color: colorCard.background,
                    borderColor: colorCard.background,
                    backgroundColor: colorCard.bodyText,
                },
            },
        },
    },
    webshopEntry: {
        gdprTextColor: colorCard.gdprText,
    },
    contactUs: {
        activeBackgroundColor: colorCard.accordionClosed,
        activeHeaderColor: colorCard.accordionClosed,
        backgroundColor: colorCard.dividerLine,
    },
    link: {
        showUnderline: false,
        showArrow: false
    },
    icons: {
        expandIcon: (React.createElement("svg", { width: "23", height: "23", viewBox: "0 0 23 23", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.8767 11.6184L0.746455 11.6184C0.620221 11.6184 0.509766 11.5789 0.415091 11.5C0.320416 11.4211 0.273078 11.3028 0.273078 11.145C0.273078 10.9872 0.312526 10.8689 0.391422 10.79C0.470318 10.7111 0.588663 10.6716 0.746455 10.6716L10.8767 10.6716L10.8767 0.541364C10.8767 0.41513 10.9162 0.304676 10.9951 0.21C11.074 0.115325 11.1923 0.0679873 11.3501 0.0679873C11.5079 0.0679873 11.6262 0.107435 11.7051 0.186332C11.784 0.265228 11.8235 0.383572 11.8235 0.541364L11.8235 10.6716L21.9537 10.6716C22.08 10.6716 22.1904 10.7111 22.2851 10.79C22.3798 10.8689 22.4271 10.9872 22.4271 11.145C22.4271 11.3028 22.3877 11.4211 22.3088 11.5C22.2299 11.5789 22.1115 11.6184 21.9537 11.6184L11.8235 11.6184L11.8235 21.7486C11.8235 21.8749 11.784 21.9853 11.7051 22.08C11.6262 22.1747 11.5079 22.222 11.3501 22.222C11.1923 22.222 11.074 22.1826 10.9951 22.1037C10.9162 22.0248 10.8767 21.9064 10.8767 21.7486L10.8767 11.6184Z", fill: "#C3002F" }))),
        expandIconRotation: "45",
        comparisonTableExpand: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 15.0282L19.0734 7.9548C19.1789 7.84934 19.307 7.79661 19.4576 7.79661C19.6083 7.79661 19.7363 7.84934 19.8418 7.9548C19.9473 8.06026 20 8.18832 20 8.33898C20 8.48964 19.9473 8.6177 19.8418 8.72316L12.9492 15.6158C12.8136 15.7514 12.6629 15.8493 12.4972 15.9096C12.3315 15.9699 12.1657 16 12 16C11.8343 16 11.6686 15.9699 11.5028 15.9096C11.3371 15.8493 11.1864 15.7514 11.0508 15.6158L4.15819 8.72316C4.05273 8.6177 4 8.48964 4 8.33898C4 8.18832 4.05273 8.06026 4.15819 7.9548C4.26365 7.84934 4.39171 7.79661 4.54237 7.79661C4.69303 7.79661 4.82109 7.84934 4.92655 7.9548L12 15.0282Z", fill: "#C3002F", stroke: "#C3002F" }))),
        comparisonTableExpandRotation: "180",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#020A0D" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4731 12.4734L12.4731 22.6036C12.4731 22.7299 12.4337 22.8403 12.3548 22.935C12.2759 23.0297 12.1575 23.077 11.9997 23.077C11.842 23.077 11.7236 23.0376 11.6447 22.9587C11.5658 22.8798 11.5264 22.7614 11.5264 22.6036L11.5264 12.4734L1.3961 12.4734C1.26987 12.4734 1.15941 12.4339 1.06474 12.355C0.970061 12.2761 0.922724 12.1578 0.922724 12C0.922724 11.8422 0.962172 11.7239 1.04107 11.645C1.11996 11.5661 1.23831 11.5266 1.3961 11.5266L11.5264 11.5266L11.5264 1.39636C11.5264 1.27012 11.5658 1.15967 11.6447 1.06499C11.7236 0.970318 11.842 0.92298 11.9997 0.92298C12.1575 0.92298 12.2759 0.962428 12.3548 1.04132C12.4337 1.12022 12.4731 1.23856 12.4731 1.39636L12.4731 11.5266L22.6034 11.5266C22.7296 11.5266 22.8401 11.5661 22.9347 11.645C23.0294 11.7239 23.0768 11.8422 23.0768 12C23.0768 12.1578 23.0373 12.2761 22.9584 12.355C22.8795 12.4339 22.7612 12.4734 22.6034 12.4734L12.4731 12.4734Z", fill: "#C3002F" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#646B52" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { opacity: "0.4" },
                React.createElement("path", { d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#020A0D" })))),
        tooltipCloseIcon: (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close" },
                React.createElement("path", { id: "Vector", d: "M8 8.66946L0.83682 15.8326C0.747559 15.9219 0.641562 15.9721 0.518828 15.9833C0.396095 15.9944 0.27894 15.9442 0.167364 15.8326C0.055788 15.7211 0 15.6095 0 15.4979C0 15.3863 0.055788 15.2748 0.167364 15.1632L7.33054 8L0.167364 0.83682C0.0781032 0.747559 0.027894 0.641562 0.0167364 0.518828C0.0055788 0.396095 0.055788 0.27894 0.167364 0.167364C0.27894 0.055788 0.390516 0 0.502092 0C0.613668 0 0.725244 0.055788 0.83682 0.167364L8 7.33054L15.1632 0.167364C15.2524 0.0781032 15.3584 0.027894 15.4812 0.0167364C15.6039 0.0055788 15.7211 0.055788 15.8326 0.167364C15.9442 0.27894 16 0.390516 16 0.502092C16 0.613668 15.9442 0.725244 15.8326 0.83682L8.66946 8L15.8326 15.1632C15.9219 15.2524 15.9721 15.3584 15.9833 15.4812C15.9944 15.6039 15.9442 15.7211 15.8326 15.8326C15.7211 15.9442 15.6095 16 15.4979 16C15.3863 16 15.2748 15.9442 15.1632 15.8326L8 8.66946Z", fill: "white" })))),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#F5F4F3"/>
</svg>`),
        bulletIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#646B52"/>
</svg>`),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3245 15.1123L10.3333 15.1211L10.3422 15.1123L16.6065 8.84792C16.701 8.7535 16.7938 8.7079 16.8851 8.7079C16.9764 8.7079 17.0692 8.7535 17.1636 8.84792C17.2575 8.94188 17.2984 9.03899 17.2893 9.13967C17.28 9.24205 17.2382 9.33034 17.1636 9.40495L10.9279 15.6406C10.7577 15.8108 10.5597 15.8955 10.3333 15.8955C10.107 15.8955 9.90895 15.8108 9.73872 15.6406L6.77895 12.6808C6.68453 12.5864 6.63894 12.4936 6.63894 12.4023C6.63894 12.311 6.68453 12.2182 6.77895 12.1238C6.87291 12.0298 6.97003 11.9889 7.07071 11.9981C7.17308 12.0074 7.26137 12.0492 7.33599 12.1238L10.3245 15.1123ZM12.005 21.9875C10.624 21.9875 9.32556 21.7255 8.1096 21.2014C6.89339 20.6773 5.83557 19.966 4.93605 19.0676C4.03654 18.1692 3.32438 17.1128 2.79958 15.8981C2.27488 14.6837 2.0125 13.386 2.0125 12.005C2.0125 10.624 2.27455 9.32556 2.79858 8.1096C3.32273 6.89339 4.034 5.83558 4.93238 4.93607C5.83078 4.03655 6.88725 3.32438 8.10191 2.79958C9.31633 2.27488 10.614 2.0125 11.995 2.0125C13.376 2.0125 14.6744 2.27455 15.8904 2.79858C17.1066 3.32273 18.1644 4.034 19.0639 4.93238C19.9635 5.83078 20.6756 6.88725 21.2004 8.10191C21.7251 9.31633 21.9875 10.614 21.9875 11.995C21.9875 13.376 21.7255 14.6744 21.2014 15.8904C20.6773 17.1066 19.966 18.1644 19.0676 19.064C18.1692 19.9635 17.1128 20.6756 15.8981 21.2004C14.6837 21.7251 13.386 21.9875 12.005 21.9875ZM12 21.2079C14.5703 21.2079 16.7478 20.3158 18.5318 18.5318C20.3158 16.7478 21.2079 14.5703 21.2079 12C21.2079 9.4297 20.3158 7.25218 18.5318 5.46817C16.7478 3.68417 14.5703 2.7921 12 2.7921C9.4297 2.7921 7.25218 3.68417 5.46817 5.46817C3.68417 7.25218 2.7921 9.4297 2.7921 12C2.7921 14.5703 3.68417 16.7478 5.46817 18.5318C7.25218 20.3158 9.4297 21.2079 12 21.2079Z" fill="#C3002F" stroke="#C3002F" stroke-width="0.025"/>
</svg>`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { opacity: "0.4" },
                React.createElement("path", { d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#0A0A0A" })))),
        helpIcon: (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.001 15C10.1153 15 10.2107 14.9614 10.2874 14.8843C10.364 14.8072 10.4023 14.7117 10.4023 14.5977V9.25287C10.4023 9.13889 10.3636 9.04334 10.2864 8.96624C10.2091 8.88913 10.1133 8.85057 9.99899 8.85057C9.88472 8.85057 9.78927 8.88913 9.71264 8.96624C9.63601 9.04334 9.5977 9.13889 9.5977 9.25287V14.5977C9.5977 14.7117 9.63635 14.8072 9.71365 14.8843C9.79095 14.9614 9.88673 15 10.001 15ZM10 7.41379C10.1628 7.41379 10.2993 7.35872 10.4095 7.24856C10.5196 7.13841 10.5747 7.00192 10.5747 6.83908C10.5747 6.67625 10.5196 6.53975 10.4095 6.4296C10.2993 6.31944 10.1628 6.26437 10 6.26437C9.83716 6.26437 9.70067 6.31944 9.59052 6.4296C9.48036 6.53975 9.42529 6.67625 9.42529 6.83908C9.42529 7.00192 9.48036 7.13841 9.59052 7.24856C9.70067 7.35872 9.83716 7.41379 10 7.41379ZM10.005 20C8.62236 20 7.32224 19.7376 6.10465 19.2129C4.88705 18.6882 3.8279 17.976 2.92721 17.0765C2.02654 16.1769 1.31351 15.1191 0.788103 13.903C0.262701 12.687 0 11.3876 0 10.005C0 8.62236 0.262366 7.32224 0.787098 6.10466C1.31185 4.88705 2.02399 3.82791 2.92353 2.92724C3.82309 2.02655 4.8809 1.31351 6.09695 0.788104C7.31301 0.262702 8.61236 0 9.995 0C11.3776 0 12.6778 0.262366 13.8953 0.787097C15.1129 1.31185 16.1721 2.02399 17.0728 2.92353C17.9734 3.82309 18.6865 4.8809 19.2119 6.09695C19.7373 7.31301 20 8.61236 20 9.995C20 11.3776 19.7376 12.6778 19.2129 13.8953C18.6882 15.113 17.976 16.1721 17.0765 17.0728C16.1769 17.9735 15.1191 18.6865 13.903 19.2119C12.687 19.7373 11.3876 20 10.005 20ZM10 19.1954C12.567 19.1954 14.7414 18.3046 16.523 16.523C18.3046 14.7414 19.1954 12.567 19.1954 10C19.1954 7.43295 18.3046 5.25862 16.523 3.47701C14.7414 1.6954 12.567 0.804598 10 0.804598C7.43295 0.804598 5.25862 1.6954 3.47701 3.47701C1.6954 5.25862 0.804598 7.43295 0.804598 10C0.804598 12.567 1.6954 14.7414 3.47701 16.523C5.25862 18.3046 7.43295 19.1954 10 19.1954Z", fill: "#C3002F" }))),
        linkArrowIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 19 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M18.655 8.496C15.3363 11.1796 11.877 13.6845 8.2918 16L7.5624 15.0197C10.8125 12.9482 13.3042 11.2093 16.7177 8.601H0V7.399H16.7761C13.3567 4.7965 10.8651 3.05762 7.6149 0.98031L8.2918 0C11.8786 2.31734 15.338 4.8262 18.655 7.5157V8.496Z", fill: "#EC6528" })))
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Nissan"),
        color: colorCard.primary,
        root: {
            fontFamily: GetFontFamily("Nissan"),
        },
        h1: {
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.125rem",
                lineHeight: "1.625rem",
            },
        },
        h4: {
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            color: colorCard.subhead,
            fontSize: "1.25rem",
            lineHeight: "1.625rem",
            fontWeight: 400,
        },
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
    },
    overrides: {
        MuiTypography: {
            root: {},
            colorPrimary: {
                color: colorCard.primary,
            },
            colorTextSecondary: {
                color: colorCard?.bodyText,
            }
        },
        MuiLink: {
            underlineHover: {
                fontFamily: GetFontFamily("Nissan"),
                "&:hover": {
                    textDecoration: "none",
                },
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiMobileStepper: {
            dotActive: {
                backgroundColor: colorCard.label
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderWidth: "1px",
                borderColor: colorCard.wcag,
            },
        },
        MuiInputBase: {
            root: {
                color: colorCard.label,
            },
        },
        MuiFormLabel: {
            root: {
                color: colorCard?.headlines,
                "&.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "&.Mui-error": {
                    color: colorCard?.error,
                },
            },
        },
        MuiButtonBase: {
            root: {
                fontWeight: 500
            },
        },
        MuiButton: {
            root: {
                fontWeight: 500,
                borderRadius: 24
            },
            outlinedPrimary: {
                color: colorCard?.headlines,
                backgroundColor: colorCard.background,
                border: `1px solid ${colorCard.headlines}`,
                "&:hover": {
                    color: colorCard?.primaryHover,
                    border: `1px solid ${colorCard.primaryHover}`,
                },
            },
            containedPrimary: {
                color: colorCard?.background,
                backgroundColor: colorCard?.headlines,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                },
            },
        },
    },
};
export default createTheme(nissanno);
