import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#0078D6",
    white: "#FFFFFF",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    grey1: "#F8F8F8",
    background2: "#F8F8F8",
    accordionClosed: "#F8F8F8",
    grey2: "#E8E8E8",
    tableOutline: "#E8E8E8",
    dividerLine: "#E8E8E8",
    secondaryHover: "#E8E8E8",
    grey3: "#9F9F9F",
    background3: "#9F9F9F",
    grey4: "#767676",
    wcagOutline: "#767676",
    inputOutline: "#767676",
    placeholderText: "#767676",
    grey5: "#5C5C5C",
    wcag: "#5C5C5C",
    gdprText: "#5C5C5C",
    helperText: "#5C5C5C",
    primaryHover: "#014880",
    tetriaryHover: "#5C5C5C",
    grey6: "#333333",
    subhead: "#333333",
    bodyText: "#333333",
    black: "#000000",
    headlines: "#000000",
    label: "#000000",
    inputText: "#000000",
    green: "#029640",
    success: "#029640",
    green2: "#D6EEE0",
    successBackground: "#D6EEE0",
    blue: "#1998FC",
    info: "#1998FC",
    blue2: "#D6EBF9",
    infoBackground: "#D6EBF9",
    yellow: "#FBD11A",
    warning: "#FBD11A",
    yellow2: "#FFF8D6",
    warningBackground: "#FFF8D6",
    red: "#EB0D3F",
    error: "#EB0D3F",
    red2: "#FBD6DD",
    errorBackground: "#FBD6DD",
    darkThemeTetriary: "#1998FC",
};
const variables = {
    border: {
        width: "1px",
        type: "solid",
    },
    textDecoration: "none",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const mercedes = {
    themeName: "mercedes",
    colorCard: colorCard,
    brand: BrandsEnum.Mercedes,
    shape: {
        borderRadius: 0,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: variables.textDecoration,
            },
            secondaryButton: {
                "&:hover": {
                    backgroundColor: "rgba(0, 120, 214, 0.04)",
                },
            },
            tetriaryLink: {
                textDecoration: "none",
            },
            textArea: {
                a: {
                    color: colorCard.grey5,
                },
                h1: {
                    color: colorCard.headlines,
                },
                h2: {
                    color: colorCard.headlines,
                },
                h3: {
                    color: colorCard.headlines,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `${variables.border.width} ${variables.border.type} ${colorCard.dividerLine}`,
                borderBottom: `1px solid ${colorCard.dividerLine}`,
                backgroundColor: colorCard.white,
            },
            accordionHeader: {
                "&.MuiAccordionSummary-root.Mui-expanded": {
                    backgroundColor: colorCard.white,
                },
                backgroundColor: colorCard.grey1,
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        AlertBlock: {
            header: {
                color: colorCard.headlines,
            },
        },
        MegaMenu: {
            link: {
                color: colorCard.grey6,
            },
            divider: {
                backgroundColor: colorCard.dividerLine,
            },
            collapseWrapper: {
                backgroundColor: colorCard.background,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.black,
            },
            innerWrapper: {
                backgroundColor: colorCard.black,
            },
            breadCrumbsBox: {
                color: colorCard.white,
                "& a": {
                    color: colorCard.white,
                },
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.headlines,
            },
            cardLinkInversed: {
                "& .MuiTypography-colorPrimary": {
                    color: colorCard.darkThemeTetriary,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.primary,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.headlines,
            },
        },
        CustomizedTextBoxTooltip: {
            tooltipIcon: {
                width: 24,
                height: 24,
            },
        },
        CustomizedTextBox: {
            textBoxWrapper: {
                background: colorCard.background2,
            },
        },
        Footer: {
            backgroundColor: colorCard.black,
            logoWrapper: {
                width: 120,
            },
            additionalLogoWrapper: {
                marginTop: 4,
            },
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.white,
            },
            wrapper: {
                borderBottom: `${variables.border.width} ${variables.border.type} ${colorCard.grey5}`,
                backgroundColor: colorCard.black,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    background: colorCard.tetriaryHover,
                },
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.primary,
                },
            },
            footerBottomWrapper: {
                backgroundColor: colorCard.black,
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            footerNoOverflow: {
                backgroundColor: colorCard.black,
            },
        },
        FooterLinkBlocks: {
            divider: {
                backgroundColor: colorCard.tetriaryHover,
            },
        },
        Header: {
            headerBlock: {
                backgroundColor: colorCard.black,
            },
            logoWrapper: {
                width: 120,
            },
            additionalLogoWrapper: {
                marginTop: 6,
            },
            divider: {
                height: 1,
                backgroundColor: colorCard.grey5,
            },
            toolbar: {
                backgroundColor: colorCard.black,
                borderBottom: `1px solid ${colorCard.tetriaryHover}`,
            },
            link: {
                color: colorCard.black,
            },
            menuButtonText: {
                color: colorCard.white,
            },
            loginText: {
                color: colorCard.white,
            }
        },
        Navigation: {
            appmenu: {
                backgroundColor: colorCard.white,
                [theme.breakpoints.up("md")]: {
                    backgroundColor: colorCard.black,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "auto",
                },
            },
            menuButtonText: {
                color: colorCard.white,
            },
        },
        NavigationItems: {
            mobMenuItem: {
                color: colorCard.grey6,
                "&.active": {
                    color: colorCard.primary,
                },
            },
            text: {
                "&.active": {
                    color: colorCard?.primary,
                },
                fontFamily: theme.typography.fontFamily,
                color: colorCard.white,
            },
            activeItem: {
                color: colorCard.background,
            },
        },
        Item: {
            link: {
                color: colorCard.bodyText,
            },
            active: {
                textDecorationColor: colorCard.primary,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: variables.textDecoration,
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: variables.textDecoration,
            },
        },
        TeaserFullPage: {
            root: {
                border: variables.textDecoration,
            },
        },
        TextBlock: {
            textBlock: {
                color: colorCard.grey5,
            },
            text: {
                "& a": {
                    color: colorCard.grey5,
                },
            },
        },
        WebshopEntryBlock: {
            fullBackgroundWrapper: {
                background: `linear-gradient(to bottom, ${colorCard.grey2} 0%, ${colorCard.grey2} 100%) no-repeat`,
                withoutBackground: {
                    "& .MuiOutlinedInput-input": {
                        background: colorCard.background2,
                    },
                },
            },
        },
        USPContainerBlock: {
            wrapper: {
                background: colorCard.black,
            },
        },
        ContactUsAreaTabHead: {
            headerText: {
                color: colorCard.black,
            },
        },
        ContactUsInfoBlock: {
            infoHeader: {
                color: colorCard.black,
            },
        },
        USPBlock: {
            header: {
                color: colorCard.white,
                paddingBottom: 12,
            },
            text: {
                color: colorCard.white,
            },
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                backgroundColor: colorCard.black,
            },
        },
    },
    teaser: {
        icon: {
            textIndent: "0px",
            paddingLeft: "30px",
            backgroundPosition: "0 0px"
        }
    },
    icons: {
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M2 6.5L12 16.5L22 6.5", stroke: colorCard.primary }))),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotationNavigation: "180",
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 15.1818V16.0909M10.5455 8.36364C10.5455 8.36364 14.2727 6.90909 14.7273 9.27273C15.0909 11 12 13.0909 12 13.0909V14.2727M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z", stroke: "black" }))),
        contactUsExpandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 12.871L9.54839 18.2903L20 5", stroke: colorCard.primary }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.871L9.54839 18.2903L20 5" stroke="#0078D6" />
    </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.08871 18.4501L4 12.1317L5.71901 10.7499L9.19449 15.0694L18.3691 5L20 6.4854L9.08871 18.4501Z" fill="#F8F8F8"/>
    </svg>`),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "currentColor" }))),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "currentColor" }))),
        bulletIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.08871 18.4501L4 12.1317L5.71901 10.7499L9.19449 15.0694L18.3691 5L20 6.4854L9.08871 18.4501Z" fill="#0078D6"/>
    </svg>`),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    contactUs: {
        backgroundColor: colorCard.tableOutline,
    },
    typography: {
        fontFamily: GetFontFamily("MBCorpoSText"),
        color: colorCard.grey6,
        root: {
            fontFamily: GetFontFamily("MBCorpoSText"),
        },
        h1: {
            fontFamily: GetFontFamily("MBCorpoATitle-Regular-Web"),
            fontSize: "2.5rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.25rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("MBCorpoATitle-Regular-Web"),
            fontSize: "2rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.75rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontSize: "1.5rem",
            lineHeight: "1.75rem",
            fontWeight: 700,
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            color: colorCard.headlines,
            "word-break": "break-word",
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontWeight: 700,
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard.grey6,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("MBCorpoSText"),
            },
            colorTextSecondary: {
                color: colorCard.grey6,
            },
            colorPrimary: {
                color: colorCard.primary,
            },
            gutterBottom: {
                color: colorCard.headlines,
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard.white,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
            dotActive: {
                backgroundColor: colorCard.primary,
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                background: colorCard.background2,
                border: "none",
            },
            input: {
                borderRadius: 2,
                border: `1px solid ${colorCard.grey4}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75, // safe to use InputLabel + <SvgIcon size='small' />
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard.primary,
            },
            iconButton: {
                backgroundColor: colorCard.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        backgroundColor: colorCard.grey1,
        MuiSvgIcon: {
            root: {
                fill: colorCard.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.white,
                borderColor: colorCard?.white,
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(mercedes);
